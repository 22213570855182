<template>
  <form novalidate @submit.prevent="login">
    <div class="logo">
      <div class="wrapper">
        <img src="../assets/laferte-noir.png" />
      </div>
    </div>
    <md-field>
      <label>{{ $t('login.username') }}</label>
      <md-input v-model="username"></md-input>
    </md-field>
    
    <md-field>
      <label>{{ $t('login.password') }}</label>
      <md-input type="password" v-model="password"></md-input>
    </md-field>

    <md-button type="submit" class="md-primary" :disabled="sending">{{ $t('login.login') }}</md-button>

    <div v-if="badLogin" class="badLogin">{{ $t('login.error') }}</div>
  </form>
</template>

<script>
  import i18n from '@/i18n';

  export default {
    data() {
      return {
        username: '',
        password: '',
        badLogin: false,
        sending: false
      };
    },
    methods: {
      async login() {
        this.sending = true;

        try {
          await this.$store.dispatch('app/login', { username: this.username, password: this.password });
          this.$nextTick(() => {
            this.$router.push({ name: i18n.locale });
          });
        } catch {
          this.badLogin = true;
        }

        this.sending = false;
      }
    },
    watch: {
      username() {
        this.badLogin = false;
      },
      password() {
        this.badLogin = false;
      }
    }
  };
</script>
<style type="text/css">
  form .logo{
    padding-bottom:1.50rem;
  }
  form .logo .wrapper{
    max-width: 200px;
    margin: 0 auto;
  }
  form.contentRight button[type="submit"].md-button{
    width: 100%;
    font-weight: bold;
    color: #fff;
    background: #e20613;
    padding: 1rem 1.5rem;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    transition: .25s;
    height: auto;
    margin: 0 auto;
    margin-top:1.00rem;
  }
  form.contentRight button[type="submit"].md-button:hover{
    background: #86040b;
  }
  form.contentRight .md-field.md-theme-default:before{
    background-color: #2a2a2a;
  }
  form .md-field.md-theme-default.md-focused label{
    color:#2a2a2a;
  }
  form .md-field.md-theme-default.md-focused .md-input{
    color:#2a2a2a;
  }
  form .login{
    padding-top:1.00rem;
  }
  form .badLogin{
    color:#FF5F5F;
    padding-top:1.00rem;
    text-align:center;
    font-size:1.25rem;
  }
</style>
